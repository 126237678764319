import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';

const nsBase = 'page';
const ns = `${ nsBase }-404`;

const NotFoundPage = () => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'404: Not found'} />
			<div className={'container-fluid'}>
				<h1>NOT FOUND</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</div>
		</div>
	);
};

export default NotFoundPage;
